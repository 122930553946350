import {RouteProps} from './utils/types';
// import './../public/voroshum.pdf'

export const routes: RouteProps = t => [
	{
		path: '/',
		label: t('header.menu.home'),
		pagePath: '/Home',
		navBar: true
	},
	{
		path: '/marches',
		label: t('header.menu.scheduleMenu.marches'),
		pagePath: '/Schedule',
		navBar: true
		// subMenu: [
		// 	{
		// 		path: '/schedule/marches',
		// 		label: t('header.menu.scheduleMenu.marches')
		// 	},
		// 	{
		// 		path: '/schedule/timetable',
		// 		label: t('header.menu.scheduleMenu.timetable')
		// 	}
		// ]
	},
	{
		path: '/about',
		label: t('header.menu.about'),
		pagePath: '/About',
		navBar: true
	},
	{
		path: '/tickets',
		label: t('header.menu.tickets'),
		pagePath: '/Tickets',
		navBar: true
	},
	{
		path: '/payment/:paymentStatus',
		pagePath: '/Payment',
		label: t('header.menu.tickets'),
		navBar: false
	},
	{
		path: '/FAQ',
		label: t('header.menu.FAQ'),
		pagePath: '/FAQ',
		navBar: true
	},
	{
		path: '*',
		label: '404',
		pagePath: '/NotFound',
		navBar: false
	},
	{
		path: '/contact',
		label: t('header.menu.contact'),
		pagePath: '/Contact',
		navBar: true
	},
	{
		path: '/news/:city',
		label: t('header.menu.news.main'),
		pagePath: '/News',
		navBar: true,
		subMenu: [
			{
				path: '/news/vanadzor',
				label: t('header.menu.news.vanadzor')
			},
			{
				path: '/news/gyumri',
				label: t('header.menu.news.gyumri')
			}
		]
	},
	{
		path: '/news/:cityId/:id',
		label: t('header.menu.news'),
		pagePath: '/NewsInfo',
		navBar: false
	},
	{
		path: '/privacyPolicy',
		label: t('footer.secondMenu.privacyPolicy'),
		pagePath: '/PrivacyPolicy'
	},
	{
		path: '/termsAndConditions',
		label: t('footer.secondMenu.termsConditions'),
		pagePath: '/TermsAndConditions'
	},
	{
		path: '/account/:name',
		label: 'asdasd',
		pagePath: '/Account',
		role: [4, 3],
		isPrivate: true
	},
	{
		path: '/account',
		label: t('header.menu.account'),
		pagePath: '/Account',
		subMenu: [
			{
				path: '/account/myBalance',
				label: t('pages.account.myBalancePage.title')
			},
			{
				path: '/account/tickets',
				label: t('header.loged_in_menu_user.tickets')
			},
			{
				path: '/account/ticketHolders',
				label: t('pages.account.ticketHolders')
			},
			{
				path: '/account/paymentHistory',
				label: t('header.loged_in_menu_user.paymentHistory')
			},
			{
				path: '/account/marchHistory',
				label: t('header.loged_in_menu_user.marchHistory')
			},
			{
				path: '/account/complaints',
				label: t('header.loged_in_menu_user.complaints')
			},
			// {
			// 	path: '/account/notifications',
			// 	label: t('header.loged_in_menu_user.notifications')
			// }
		],
		isPrivate: true,
		role: [4]
	},
	{
		path: '/partner/:name',
		label: '',
		pagePath: '/Account',
		role: [3],
		isPrivate: true
	},
	{
		path: '/partner',
		label: t('pages.account.partner.partner.title'),
		pagePath: '/Account',
		subMenu: [
			{
				path: '/partner/routes',
				label: t('header.loged_in_menu_partner.routes')
			},
			{
				path: '/partner/buses',
				label: t('pages.account.partner.buses.title')
			},
			{
				path: '/partner/drivers',
				label: t('pages.account.partner.drivers.title')
			},
			// {
			// 	path: '/partner/providedServices',
			// 	label: t('header.loged_in_menu_partner.providedServices')
			// },
			{
				path: '/partner/transfersHistory',
				label: t('header.loged_in_menu_partner.transfersHhistory')
			},
			{
				path: '/partner/transactions',
				label: t('header.loged_in_menu_partner.transactions')
			}
		],
		isPrivate: true,
		role: [3]
	}
];
