import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import SERVICES from '../services';
import {t} from 'i18next';
const initialState = {
	data: [],
	formErrors: {}
};

export const getauthOtp: any = createAsyncThunk('getauthOtp', async (state: any, {rejectWithValue}) => {
	let data: any;
	try {
		data = await SERVICES.AUTHOTP.getauthOtp(state);
	} catch (err) {
		return rejectWithValue(err);
	}
	return new Promise(resolve => resolve(data));
});

export const confirmEmailWithOtp: any = createAsyncThunk(
	'confirmEmailWithOtp',
	async (state: any, {rejectWithValue}) => {
		let data: any;
		try {
			data = await SERVICES.AUTHOTP.confirmEmailWithOtp(state);
		} catch (err) {
			return rejectWithValue(err);
		}
		return new Promise(resolve => resolve(data));
	}
);

const authOtp = createSlice({
	name: 'authOtp',
	initialState,
	reducers: {
		cleanFormErrors: state => {
			state.formErrors = {};
		}
	},
	extraReducers: builder => {
		builder
			.addCase(getauthOtp.fulfilled, (state, action) => {
				state.data = action.payload.data;
			})
			.addCase(getauthOtp.rejected, (state, action: any) => {
				state.formErrors = t(`messages.code.${action.payload.code}`);
			});
	}
});
export const {cleanFormErrors} = authOtp.actions;

export default authOtp.reducer;
