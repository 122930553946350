import {useTranslation} from 'react-i18next';
import i18n from '../../../../../utils/translate/i18';
import moment from 'moment';

const TransfersHistoryData = ({options}: any) => {
	const {t} = useTranslation();

	const getTagColor = (statusId: number) => {
		switch (statusId) {
			case 1:
				return '#009e83';
			case 2:
				return '#9E9E9E';
			default:
				return 'black';
		}
	};

	return (
		<div className="transfers-history-data">
			<div className="transfers-history-data-container">
				<div className="transfers-history-data-content">
					<div className="transfers-history-data-row">
						<div className="transfers-history-data__item">
							<div className="transfers-history-data__item-row">
								<div className="transfers-history-box-data">
									{moment(options.transferDate).format('DD-MM-YYYY HH:mm')}
								</div>
							</div>
							<div className="transfers-history-data__item-row drivers-modal-top">
								<div className="transfers-history-data__item-main">
									<div className="transfers-history-data__item-title">
										{t('pages.account.partner.transfersHistory.transferStatus')}
									</div>
									<div
										className="transfers-history-data__item-info"
										style={{color: getTagColor(options.statusId)}}>
										{options.status ? options.status[i18n.language] : '-'}
									</div>
								</div>
								<div className="transfers-history-data__item-main">
									<div className="transfers-history-data__item-title">
										{t('pages.account.partner.transfersHistory.periodStartDate')}
									</div>
									<div className="transfers-history-data__item-info">
										{moment(options.periodStartDate).format('DD-MM-YYYY')}
									</div>
								</div>
								<div className="transfers-history-data__item-main">
									<div className="transfers-history-data__item-title">
										{t('pages.account.partner.transfersHistory.periodEndDate')}
									</div>
									<div className="transfers-history-data__item-info">
										{moment(options.periodEndDate).format('DD-MM-YYYY')}
									</div>
								</div>
								<div className="transfers-history-data__item-main">
									<div className="transfers-history-data__item-title">
										{t('pages.account.partner.transfersHistory.totalamount')}
									</div>
									<div
										className="transfers-history-data__item-info"
										style={{color: getTagColor(options.statusID)}}>
										{options.totalAmount} {t('AMD')}
									</div>
								</div>
								<div className="transfers-history-data__item-main">
									<div className="transfers-history-data__item-title">
										{t('pages.account.partner.transfersHistory.transferNumber')}
									</div>
									{options.transferNumber ? (
										<div className="transfers-history-data__item-info">
											{options.transferNumber}
										</div>
									) : (
										'-'
									)}
								</div>
								<div className="transfers-history-data__item-main">
									<div className="transfers-history-data__item-title">
										{t('pages.account.partner.transfersHistory.notice')}
									</div>
									{options.notes ? (
										<div className="transfers-history-data__item-info">
											{options.notes}
										</div>
									) : (
										'-'
									)}
								</div>
								<div className="transfers-history-data__item-main">
									<div className="transfers-history-data__item-title">
										{t('complaints.attachedfile')}
									</div>
									<div className="transfers-history-data__item-info">
										{options.file ? (
											<a
												style={{color: '#009e83', textDecoration: 'underline'}}
												href={options.file}
												target="_blank">
												{t('complaints.file')}
											</a>
										) : (
											'-'
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TransfersHistoryData;
