import AUTH from './auth.api';
import NEWS from './news.api';
import USER from './user.api';
import TICKETS from './tickets.api';
import FAQS from './faqs.api';
import BANNER from './banner.api';
import COMPLAINTS from './complaints.api';
import HOLDERS from './holders.api';
import SETTINGS from './settings.api';
import USERTRANSACTIONS from './userTransactions.api';
import PARTNERTRANSACTIONS from './partnerTransactions.api';
import PARTNER from './partner.api';
import ROUTESPUBLIC from './routesPublic.api';
import CONTACTS from './contacts.api';
import BUSSTOPS from './busStops.api';
import HOLDERSNUBERBALANCE from './holdersNuberBalance.api';
import PARTNERTRANSPORTS from './partnerTransports.api';
import DRIVERS from './driver.api';
import PARTNERROUTES from './partnerRoutes.api';
import PARTNERTRANSFER from './partnertTansfer.api';
import FORGOTPASSWORD from './forgotPassword.api';
import AUTHOTP from './authOtp.api';
import RESETPASSWORD from './resetPassword.api';

const API = {
	AUTH,
	USER,
	TICKETS,
	FAQS,
	NEWS,
	BANNER,
	SETTINGS,
	COMPLAINTS,
	HOLDERS,
	USERTRANSACTIONS,
	PARTNERTRANSACTIONS,
	PARTNER,
	ROUTESPUBLIC,
	CONTACTS,
	BUSSTOPS,
	HOLDERSNUBERBALANCE,
	PARTNERTRANSPORTS,
	DRIVERS,
	PARTNERROUTES,
	PARTNERTRANSFER,
	FORGOTPASSWORD,
	AUTHOTP,
	RESETPASSWORD
};

export default API;
