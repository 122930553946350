import {Button, Form, Modal} from 'antd';
import {useForm} from 'rc-field-form';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {validateMessages} from '../../../../../Components/AuthModalForm';
import FormGroup from '../../../../../Components/formItems/FormGroup';
import i18n from '../../../../../utils/translate/i18';
import ticketCarrier from './../../../../../assets/icons/TicketCarrier.svg';
import reverse from './../../../../../assets/icons/reverse.svg';
import reversedTicket from './../../../../../assets/icons/reversedTicket.svg';
import ticketBlock from './../../../../../assets/icons/ticket-block.svg';
import movebalance_fields from './../movebalance_fields.form';
import {useLocation, useParams} from 'react-router-dom';
import {TransportCardBack, TransportCardFront} from '../../../../../Components/TransportCard/TransportCard';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {
	changHolderStatusThunk,
	getHolderByIDThunk,
	getHoldersThunk
} from '../../../../../store/myHolders.slice';
import axios from 'axios'; // Make sure axios is installed
import Toast from '../../../../../Components/Toast';

interface TicketHolderItemProps {
	options: any;
	getbalance: string;
	ticketId: any;
}

const TicketHolderItem = ({options, getbalance, ticketId}: TicketHolderItemProps) => {
	const location = useLocation();
	const {t} = useTranslation();
	const [modal, setModal] = useState([false || location.hash === '#showBalance', false]);
	const [blockPopUp, setBlockPopUp] = useState(false);
	const dispatch = useDispatch<any>();
	const [form] = useForm<any>();
	const formErrors = useSelector((state: any) => state.ticketHolders.formErrors);
	const [flip, setFlip] = useState(false);

	useEffect(() => {
		if (modal[0] || modal[1]) {
			dispatch(getHolderByIDThunk(ticketId));
		}
	}, [modal, ticketId]);
	const callPopup = useCallback((type: string, open: boolean) => {
		switch (type) {
			case 'checkBalance':
				setModal(p => {
					p[0] = open;
					return [...p];
				});
				break;
			case 'moveBalance':
				setModal(p => {
					p[1] = open;
					return [...p];
				});
				break;
		}
	}, []);

	const onSubmit = async (values: any) => {
		try {
			// Implement form submission logic here
			const response = await axios.post('/api/moveBalance', values);

			// Optionally, you can close the modal and reset form state here
			callPopup('moveBalance', false);
			form.resetFields();
		} catch (error: any) {
			console.error('Error:', error);
			// Handle error response and set form errors
			formErrors(error.response?.data?.errors || {});
		}
	};

	const handleChangesStatus = () => {
		dispatch(changHolderStatusThunk({statusId: options.statusId === 1010 ? 1020 : 1030, ticketId}))
			.unwrap()
			.then(() => {
				setBlockPopUp(false);
				dispatch(getHoldersThunk({}));
				Toast({type: 'success', description: t('messages.success.success')});
			})
			.catch(() => {
				Toast({type: 'error', description: t('messages.error.badRequest')});
			});
	};

	const PopupCheckBalance = () => (
		<div className="popup__container">
			<div className="popup__content">
				<div className="popup__rows">
					<div className="popup__row subtitle">
						<p>{t('pages.account.ticketHoldersPage.actions.checkBalance.popup.subTitle')}</p>
					</div>
					<div className="popup__row number">
						<p>
							<span>{getbalance}</span>
							{t('pages.account.ticketHoldersPage.actions.checkBalance.popup.march')}
						</p>
					</div>
				</div>
			</div>
		</div>
	);

	const createModal = (
		modalIndex: number,
		titleKey: string,
		className: string,
		content: React.ReactNode,
		onOk?: () => void,
		onCancel?: () => void
	) => (
		<Modal
			key={modalIndex}
			destroyOnClose
			centered
			closeIcon={null}
			className={`ticket-holder__popup ${className}`}
			title={t(`pages.account.ticketHoldersPage.actions.${titleKey}.popup.title`)}
			open={modal[modalIndex]}
			okText={t(`pages.account.ticketHoldersPage.actions.${titleKey}.popup.okText`)}
			onOk={onOk ?? (() => callPopup(titleKey, false))}
			onCancel={onCancel ?? (() => callPopup(titleKey, false))}>
			{content}
		</Modal>
	);

	const modals = [
		createModal(0, 'checkBalance', 'check-balance', <PopupCheckBalance />),
		createModal(
			1,
			'moveBalance',
			'move-balance',
			<Form layout="vertical" validateMessages={validateMessages(t)} onFinish={onSubmit}>
				<FormGroup tabFields={movebalance_fields(t)} formErrors={formErrors} />
			</Form>
		)
	];
	const getCardStyle = (statusId: number) => {
		switch (statusId) {
			case 1010:
				return '';
			case 1020:
				return 'notVerificated';
			case 1030:
				return '';
			case 1040:
				return 'notVerificated';
			default:
				return '';
		}
	};
	const cardStyle = getCardStyle(options.statusId);
	return (
		<div className="ticket-holder__item">
			<div className="ticket-holder__item-columns">
				{/* header and ticket card */}
				<div className="ticket-holder__item-column">
					<div className="ticket-holder__item-top">
						<div className="ticket-holder__item-header">
							<p>{t('pages.tickets.oneUse.ticketCard.storageType')}</p>
						</div>
					</div>
					<div className="ticket-holder-card__container">
						<div className={`ticket-holder-card__flip flip-box ${flip ? 'true' : ''}`}>
							<div className="ticket-holder-card__main flip-box-inner">
								<div className={`ticket-card__frontside flip-box-front ${cardStyle}`}>
									<TransportCardFront />
								</div>
								<div className={`ticket-card__backside flip-box-back ${cardStyle}`}>
									<TransportCardBack number={options.number} />
								</div>
							</div>
						</div>

						<div className="ticket-holder-card__reverse">
							<div className="card-reverse__main">
								<div
									className={`card-reverse__ico ${flip ? 'reversed' : ''}`}
									onClick={() => setFlip(!flip)}>
									<img src={flip ? reversedTicket : reverse} alt="" />
								</div>
							</div>
							<div className="card-reverse__title">
								<p>{t('pages.account.ticketHoldersPage.ticketHolder.rotateCard')}</p>
							</div>
						</div>
					</div>
				</div>
				{/* description and other info */}
				<div className="ticket-holder__item-column description">
					<div className="ticket-holder__item-description">
						<div className="ticket__item-description">
							<p>{t('pages.account.ticketHoldersPage.ticketHolder.description.title')}</p>
						</div>
						<div className="holder-item__more-info">
							<div className="more-info__items">
								<div className="more-info__item">
									<div className="more-info__title">
										{t(
											'pages.account.ticketHoldersPage.ticketHolder.description.main.status.title'
										)}
									</div>
									<div className="more-info__data">
										{options.statusId === 1040 || options.statusId === 1020
											? t(
													'pages.account.ticketHoldersPage.ticketHolder.description.main.status.blocked'
											  )
											: t(
													'pages.account.ticketHoldersPage.filter.status.options.activated'
											  )}
									</div>
								</div>

								<div className="more-info__item">
									<div className="more-info__title">
										{t(
											'pages.account.ticketHoldersPage.ticketHolder.description.main.registrationDate'
										)}
									</div>
									<div className="more-info__data">
										{moment(options.createdAt).format('DD-MM-YYYY')}
									</div>
								</div>
								<div className="more-info__item">
									<div className="more-info__title">
										<span className="title">
											{t(
												'pages.account.ticketHoldersPage.ticketHolder.description.main.validDate.title'
											)}
										</span>
										<span className="title1">
											{t(
												'pages.account.ticketHoldersPage.ticketHolder.description.main.validDate.title1'
											)}
										</span>
									</div>
									<div className="more-info__data">
										{moment(options.validDate).format('DD-MM-YYYY')}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* ticket holder actions */}
				<div className="ticket-holder__actions">
					<div className="ticket-holder__actions-content">
						{[
							{
								type: 'checkBalance',
								title: t('pages.account.ticketHoldersPage.actions.checkBalance'),
								icon: ticketCarrier
							}
						].map((elem, index) => (
							<div key={index} className="ticket-holder__action">
								<div className="ticket-holder__action-columns">
									<div className="ticket-holder__action-column">
										<div className="action-column__ico">
											<img src={elem.icon} alt="ico" />
										</div>
										<div className="action-column__title">
											<p>
												{t(
													`pages.account.ticketHoldersPage.actions.${elem.type}.title`
												)}
											</p>
										</div>
									</div>
									<div className="ticket-holder__action-column">
										<Button onClick={() => callPopup(elem.type, true)}>
											{t(
												`pages.account.ticketHoldersPage.actions.${elem.type}.btnTitle`
											)}
										</Button>
									</div>
								</div>
							</div>
						))}
						{/* block popup */}
						<div className="ticket-holder__action">
							<div className="ticket-holder__action-columns">
								<div className="ticket-holder__action-column">
									<div className="action-column__ico">
										<img src={ticketBlock} alt="ico" />
									</div>
									<div className="ticket-holder__action-title">
										<p>
											{t(
												`pages.account.ticketHoldersPage.actions.blockBalance.${
													options.statusId === 1010 ? 'title' : 'title1'
												}`
											)}
										</p>
									</div>
								</div>
								<div className="ticket-holder__action-column">
									<Button
										onClick={() => setBlockPopUp(true)}
										style={
											options.statusId === 1020 || options.statusId === 1030
												? {
														backgroundColor: 'gray',
														color: '#fff',
														borderColor: 'transparent'
												  }
												: {}
										}
										disabled={options.statusId === 1020 || options.statusId === 1030}>
										{t(
											`pages.account.ticketHoldersPage.actions.blockBalance.${
												options.statusId === 1010 ? 'btnTitle1' : 'btnTitle'
											}`
										)}
									</Button>
								</div>
							</div>

							<Modal
								destroyOnClose
								centered
								closeIcon={null}
								className={`ticket-holder__popup block-balance`}
								title={t(`pages.account.ticketHoldersPage.actions.blockBalance.popup.title`)}
								open={blockPopUp}
								okText={t(
									`pages.account.ticketHoldersPage.actions.blockBalance.popup.${
										options.statusId === 1010 ? 'okText' : 'okText1'
									}`
								)}
								onOk={() => handleChangesStatus()}
								onCancel={() => setBlockPopUp(false)}>
								{t(
									`pages.account.ticketHoldersPage.actions.blockBalance.popup.${
										options.statusId === 1010 ? 'subTitle' : 'subTitle1'
									}`
								)}
							</Modal>
						</div>
					</div>
				</div>
				{modals}
			</div>
		</div>
	);
};

export default TicketHolderItem;
