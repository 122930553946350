import {Divider, Modal, Tooltip} from 'antd';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import BusNumber from '../../../../../Schedule/BusNumber/BusNumber';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-routing-machine';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import destinationIcon from './../../../../../../assets/icons/destination.svg';
import busStop from './../../../../../../assets/icons/mainStop.svg';
import myLocation from './../../../../../../assets/icons/mylocation.svg';
import {useMediaQuery} from 'react-responsive';
import i18n from '../../../../../../utils/translate/i18';
import ScheduleStops from '../../../../../Schedule/ScheduleStops/ScheduleStops';
import moment from 'moment';
import SERVICES from '../../../../../../services';
interface ISorting {
	busStops: Record<string, any>[];
	startPoint: number;
	endPoint: number;
}
interface IRoutes {
	route: Record<string, any>;
	startPoint: number;
	endPoint: number;
}
function sortBusStops({busStops, startPoint, endPoint}: ISorting) {
	const retData = busStops?.sort((a: any, b: any) =>
		a.routes[0].sequence > b.routes[0].sequence ? 1 : -1
	);
	let needToReverse = false;
	const start = retData.findIndex((el: any) => el.id === startPoint);
	const end = retData.findIndex((el: any) => el.id === endPoint);
	needToReverse = start > end;
	return needToReverse ? retData.reverse() : retData;
}
const RouteItem = ({options}: any, {route, startPoint, endPoint}: IRoutes) => {
	let {t} = useTranslation();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [loading, setLoading] = useState(false);
	const [isPopup, setIsPopup] = useState(false);
	const isMobile = useMediaQuery({maxWidth: 992});
	const [busStops, setBusStops] = useState<any[]>([]);
	const [isModal, setIsModal] = useState(false);

	const showMap = (routeIds: number) => {
		setIsPopup(true);
		if (!busStops.length) {
			handleToggleClick({routeIds, fromModal: true});
		}
	};

	const handleToggleClick = async ({routeIds, fromModal}: any) => {
		try {
			if (!isOpen && !busStops?.length) {
				setLoading(true);
				const res = await SERVICES.BUS_STOPS.getBusStop({routeIds, all: true});
				setBusStops(res.data.items);
			}
		} catch (error) {
			console.error('Failed to fetch bus stops:', error);
			// Optionally show a user-friendly error message
		} finally {
			setLoading(false);
		}
		if (!fromModal) {
			setIsOpen(prev => !prev);
		}
	};

	const MapWithRouting = ({coordinates}: any) => {
		useEffect(() => {
			const map = L.map('map').setView([40.5232, 44.1946], isMobile ? 13 : 10);

			L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
				attribution: '&copy; OpenStreetMap contributors'
			}).addTo(map);

			const startIcon = L.icon({
				iconUrl: myLocation,
				iconSize: [32, 32],
				iconAnchor: [16, 32],
				popupAnchor: [0, -32]
			});

			const middleIcon = L.icon({
				iconUrl: busStop,
				iconSize: [32, 32],
				iconAnchor: [16, 32],
				popupAnchor: [0, -32]
			});

			const endIcon = L.icon({
				iconUrl: destinationIcon,
				iconSize: [32, 32],
				iconAnchor: [16, 32],
				popupAnchor: [0, -32]
			});

			// Add routing control
			// @ts-ignore
			L.Routing.control({
				waypoints: busStops.map((elem: any) => {
					const coordinates = elem.coordinates.split(',');
					let lat = coordinates[0].trim(),
						lng = coordinates[1].trim();

					return L.latLng(lat, lng);
				}),
				lineOptions: {
					styles: [
						{
							color: 'blue',
							weight: 4,
							className: 'animate'
						}
					],
					addWaypoints: false,
					// @ts-ignore
					draggableWaypoints: false
				},
				routeWhileDragging: true,

				createMarker: function (i: any, waypoint: any, n: any) {
					if (i === 0) {
						return L.marker(waypoint.latLng, {icon: startIcon});
					} else if (i === n - 1) {
						return L.marker(waypoint.latLng, {icon: endIcon});
					} else {
						return L.marker(waypoint.latLng, {icon: middleIcon});
					}
				}
			}).addTo(map);
		}, [isMobile, coordinates]);

		return <div id="map" style={{height: '70vh'}} />;
	};

	return (
		<div className="partner-routes">
			<div className="partner-routes__content">
				<div className="partner-routes-content">
					<div className="partner-routes-row">
						<div className="partner-routes__item">
							<div className="partner-routes__item-row">
								<div
									className="partner-routes__item-info"
									style={{wordWrap: 'break-word', wordBreak: 'break-all'}}>
									{options.city && options.city[i18n.language]}
								</div>
							</div>

							<Divider style={{background: '#009E83'}} />

							<div className="partner-routes__item-row drivers-modal-top">
								<div className="partner-routes__item-main">
									<div className="partner-routes__item-title">
										{t('pages.account.partner.routes.routeItem.busNum')}
									</div>
									<div className="partner-routes__item-info">
										{options?.number !== null && options?.number !== undefined ? (
											<BusNumber number={options?.number} />
										) : (
											'-'
										)}
									</div>
								</div>
								<div className="partner-routes__item-main">
									<div className="partner-routes__item-title">
										{t('pages.account.partner.routes.routeItem.busType')}
									</div>
									<div className="partner-routes__item-info">
										{options.type && options.type[i18n.language]}
									</div>
								</div>
								<div className="partner-routes__item-main">
									<div className="partner-routes__item-title">
										{t('pages.account.partner.routes.titles')}
									</div>
									<div className="partner-routes__item-info">
										{options?.mode && options.mode[i18n.language]}
									</div>
								</div>
								<div className="partner-routes__item-main">
									<div className="partner-routes__item-title">
										{t('pages.account.partner.routes.routeItem.routeAllocationDate')}
									</div>
									<div className="partner-routes__item-info">
										{moment(options.allocationStartDate).format('DD-MM-YYYY')}
									</div>
								</div>
								<div className="partner-routes__item-main">
									<div className="partner-routes__item-title">
										{t('pages.account.partner.routes.routeItem.routeAllocationEndDate')}
									</div>
									<div className="partner-routes__item-info">
										{moment(options.allocationEndDate).format('DD-MM-YYYY')}
									</div>
								</div>
								<div className="partner-routes__item-main">
									<div className="partner-routes__item-title">
										{t('pages.account.partner.routes.routeItem.routeAllocationCode')}
									</div>
									<div className="partner-routes__item-info">
										<a 
											style={{color: '#009e83', textDecoration: 'underline'}}
											href="https://test-website.etransport.am/voroshum.pdf"
											target="_blank">
											{options.allocationNumber}
										</a>
									</div>
								</div>
								<div className="partner-routes__item-main">
									<div className="partner-routes__item-title">
										{t('pages.account.partner.routes.routeItem.buttons.marchWay')}
									</div>
									<div className="partner-routes__item-info">
										<Tooltip zIndex={1} destroyTooltipOnHide>
											<a
												onClick={() => showMap(options.id)} 
												style={{color: '#009e83', textDecoration: 'underline'}}>
												{options.busStopsCount}
											</a>
										</Tooltip>
									</div>
								</div>
								<div className="partner-routes__item-main">
									<div className="partner-routes__item-title">
										{t(
											'pages.account.ticketHoldersPage.ticketHolder.description.main.status.title'
										)}
									</div>
									<div className="partner-routes__item-info">
										{options.status && options.status[i18n.language]}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="partner-route__item-row">
				<div className="route__item-actions__row">
					<div className="item-actions__btns">
						<div className="item-actions__first-column">
							<Modal
								destroyOnClose
								className="schedule-modal route-modal"
								open={isPopup}
								onCancel={() => setIsPopup(false)}
								footer={null}
								width={'90%'}
								style={{
									maxHeight: '700px',
									height: '100%'
								}}
								key={new Date().getTime()}>
								<MapWithRouting />
								<div
									className="icansss"
									style={{
										marginTop: 20,
										display: 'flex',
										flexDirection: isMobile ? 'column' : 'row',
										flexWrap: 'wrap'
									}}>
									{sortBusStops({busStops, startPoint, endPoint}).map(
										(elem: any, index: any) => (
											<ScheduleStops
												data={elem}
												key={index}
												startPoint={startPoint}
												endPoint={endPoint}
												hasNext={index < busStops.length - 1}
												isLast={index === busStops.length - 1}
											/>
										)
									)}
								</div>
							</Modal>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RouteItem;
