import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import helperFuncs from '../utils/heplerFuncs/functions';
import {ISignIn, ISignUp, IUser} from '../services/types';
import {t} from 'i18next';
import _, {update} from 'lodash';
import Toast from '../Components/Toast';
import SERVICES from '../services';

interface IAuth {
	data: IUser | null;
	modal: {visible: false | 'SIGN_IN' | 'SIGN_UP' | 'FOGOT_PASSWORD'; title: string | null};
	loading: boolean;
	formErrors: {email?: string; password?: string; phone?: string};
	error: string | null;
}

const initialState: IAuth = {
	data: helperFuncs.setUser(),
	modal: {visible: false, title: null},
	loading: false,
	formErrors: {},
	error: null
};

export const signInThunk: any = createAsyncThunk('auth/signIn', async (state: ISignIn, {rejectWithValue}) => {
	let data: any;
	try {
		data = await SERVICES.AUTH.signIn(state);
	} catch (err) {
		return rejectWithValue(err);
	}
	return new Promise(resolve => resolve(data));
});

export const signUpThunk: any = createAsyncThunk('auth/signUp', async (state: ISignUp, {rejectWithValue}) => {
	let data: any;
	try {
		data = await SERVICES.AUTH.signUp(state);
	} catch (err) {
		return rejectWithValue(err);
	}
	return new Promise(resolve => resolve(data));
});

export const getUserThunk: any = createAsyncThunk('auth/getUser', async (state: any, {rejectWithValue}) => {
	let data;
	try {
		data = await SERVICES.USER.getUser();
	} catch (err) {
		return rejectWithValue(err);
	}
	return data;
});

export const updateUserImageThunk: any = createAsyncThunk(
	'auth/userImage',
	async (params, {rejectWithValue}) => {
		const {image, id}: any = params;
		let data: any;
		try {
			data = await SERVICES.USER.updateImage({image}, id);
		} catch (err) {
			return rejectWithValue(err);
		}
		return new Promise(resolve => resolve(data));
	}
);

export const deleteUserImageThunk: any = createAsyncThunk(
	'auth/delteUserImage',
	async (id, {rejectWithValue}) => {
		let data: any;
		try {
			data = await SERVICES.USER.deleteUserImage(id);
		} catch (err) {
			return rejectWithValue(err);
		}
		return new Promise(resolve => resolve(data));
	}
);

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setFormErrors(state, action) {
			state.formErrors = {...action.payload};
		},
		setAuthModalVisible(state, action) {
			state.modal.visible = action.payload.visible;
			if (action.payload.visible !== state.modal.visible) {
				state.formErrors = {};
				state.data = null;
				state.loading = false;
			}
			if (action.payload.title) {
				state.modal.title = action.payload.title;
			}
		},
		logOutUser(state) {
			state.data = helperFuncs.setUser();
		}
	},
	extraReducers: builder => {
		/* SIGN_IN */
		builder.addCase(signInThunk.pending, state => {
			state.loading = true;
		});
		builder.addCase(signInThunk.fulfilled, (state, action) => {
			const {token} = action.payload.data;
			localStorage.setItem('token', JSON.stringify(token).replace(/"/g, ''));
		});
		builder.addCase(signInThunk.rejected, (state, action) => {
			if (typeof action.error.message === 'string' && _.has(action.payload, 'code')) {
				state.formErrors = {email: ' ', password: t(`messages.code.${action.payload.code}`)};
			} else {
				action.error.message === 'string' &&
					Toast({type: 'error', description: action.payload.message});
			}
			state.loading = false;
		});

		// /* SIGN_UP */
		builder.addCase(signUpThunk.pending, state => {
			state.loading = true;
		});
		builder.addCase(signUpThunk.fulfilled, (state, action) => {
			// if (typeof action.payload.data.message === 'string' && _.has(action.payload.data, 'code')) {
			// 	Toast({type: 'success', description: t(`messages.code.${action.payload.data.code}`)});
			// }

			state.modal.visible = false;
			state.loading = false;
		});
		builder.addCase(signUpThunk.rejected, (state, action) => {
			if (typeof action.error.message === 'string' && _.has(action.payload, 'code')) {
				state.formErrors = {email: t(`messages.code.${action.payload.code}`)};
			} else {
				action.error.message === 'string' &&
					Toast({type: 'error', description: action.payload.message});
			}
			state.loading = false;
		});
		// /* GET_USERS */
		builder.addCase(getUserThunk.pending, state => {
			state.loading = true;
		});
		builder.addCase(getUserThunk.fulfilled, (state, action) => {
			state.data = {...action.payload.data, isAuthenticated: true};
			localStorage.setItem('user', JSON.stringify(state.data));
			state.modal.visible = false;
			state.loading = false;
		});
		builder.addCase(getUserThunk.rejected, (state, action) => {
			state.data = {...action.payload.data};
			state.loading = false;
		});
		// UPDATE USER IMAGE
		builder.addCase(updateUserImageThunk.fulfilled, (state, action) => {
			// @ts-ignore
			state.data.avatar = action.payload.data.avatar;
		});
		builder.addCase(deleteUserImageThunk.fulfilled, (state, action) => {
			// @ts-ignore
			state.data.avatar = null;
		});
	}
});

export const {setAuthModalVisible, setFormErrors, logOutUser} = authSlice.actions;
export default authSlice.reducer;
