import {configureStore} from '@reduxjs/toolkit';
import authReducer from './auth.slice';
import routesReducer from './routes.slice';
import loadingReducer from './loading.slice';
import notificationsReducer from './notifications.slice';
import newsReducer from './news.slice';
import providedServicesReducer from './providedServices.slice';
import passengerTicketsReducer from './tickets.slice';
import userTicketsReducer from './account.userTickets.slice';
import passengerPaymentHistoryReducer from './account.paymentHistory';
import ticketHoldersReducer from './myHolders.slice';
import partnerRoutesReducer from './partner.routes.slice';
import partnerBusesReducer from './partnersTransports.slice';
import partnerDriversReducer from './partner.drivers.slice';
import historyMarchesReducer from './account.historyMarches.slice';
import transfersHistoryReducer from './partner.transferHistory.slice';
import faqReducer from './faq.slice';
import transactionReducer from './partner.transaction.slice';
import accountComplaintsReducer from './complaints.slic';
import bannerReducer from './banner.slice';
import accountUserInfoReducer from './account.userInfo.slice';
import partnersSlice from './partner.slice';
import routesPublic from './routesPublic.slice';
import contactsReducer from './contacts.slice';
import busStopSlice from './busStops.slice';
import holdersNuberBalanceSlice from './holdersNuberBalance.slice';
import partnersTransportsSlice from './partnersTransports.slice';
import forgotPassword from './forgotPassword.slice';
import authOtp from './authOtp.slice';
import resetPassword from './resetPassword.slice';

export const store = configureStore({
	reducer: {
		auth: authReducer,
		userInfo: accountUserInfoReducer,
		routes: routesReducer,
		loading: loadingReducer,
		news: newsReducer,
		notifications: notificationsReducer,
		providedServices: providedServicesReducer,
		myTickets: passengerTicketsReducer,
		userTickets: userTicketsReducer,
		paymentHistory: passengerPaymentHistoryReducer,
		ticketHolders: ticketHoldersReducer,
		partnerRoutes: partnerRoutesReducer,
		buses: partnerBusesReducer,
		drivers: partnerDriversReducer,
		transfersHistory: transfersHistoryReducer,
		transactions: transactionReducer,
		complaints: accountComplaintsReducer,
		faq: faqReducer,
		banner: bannerReducer,
		historyMarches: historyMarchesReducer,
		partners: partnersSlice,
		routesPublic: routesPublic,
		contacts: contactsReducer,
		busStops: busStopSlice,
		holdersBalance: holdersNuberBalanceSlice,
		partnersTransport: partnersTransportsSlice,
		forgotPassword: forgotPassword,
		authOtp: authOtp,
		resetPassword: resetPassword
	}
});

export type AppDispatch = typeof store.dispatch;
