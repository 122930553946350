import {ModalFormFieldTypesFunc} from '../../../../utils/types';

const historyMarchesform: ModalFormFieldTypesFunc = t => [
	{
		name: 'cityId',
		label: t('pages.account.historyMarches.bycity'),
		type: 'select',
		isRequired: false,
		colProps: {
			lg: 24,
			xs: 24
		},
		dictionaryApiPath: 'cities'
	},
	{
		name: 'routeNumber',
		isRequired: false,
		label: t('pages.account.historyMarches.byroute'),
		type: 'select',
		allowClear: true,
		colProps: {
			lg: 24,
			xs: 24
		},
		fetchOnDepValue: false,
		customApiFetch: {
			path: 'api/v1/routes/public',
			labelKey: val => val?.number,
			valueKey: (val: any) => {
				return `${val.id}_${val.number}` as string;
			}
		}
	},

	{
		name: 'transactionDate',
		isRequired: false,
		label: t('pages.account.historyMarches.bydate'),
		type: 'dateRange',
		colProps: {
			lg: 24,
			xs: 24
		}
	}
];

export default historyMarchesform;
