
import {useTranslation} from 'react-i18next';
import './MyBalance.less';
import {useSelector} from 'react-redux';
import OnlinePaymentTab from './OnlinePayment/OnlinePayment';

function MyBalance() {
	let {t} = useTranslation();

	let auth = useSelector(({auth}) => auth.data);


	return (
		<div className="my-balance">
			<div className="my-balance__container">
				<div className="my-balance__content">
					<div className="my-balance__row top-data">
						<div className="my-balance__top-data">
							<div className="top-data__rows">
								<div className="top-data__row">
									{t('pages.account.myBalancePage.top.userID', {id: auth.identityNumber})}
								</div>
								<div className="top-data__row">
									{t('pages.account.myBalancePage.top.balance', {count: auth.balance})}
								</div>
							</div>
						</div>
					</div>
					<div className="my-balance__row my-balance-tabs-main">
						<div className="my-balance__tabs">
						<OnlinePaymentTab />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default MyBalance;
