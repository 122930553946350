import React, {useEffect} from 'react';
import {Pagination, Select} from 'antd';
import './PagePagination.less';
import {useLocation} from 'react-router-dom';
import {t} from 'i18next';

const {Option} = Select;

type PaginationT = {
	currentPage: number;
	totalItems: number;
	pageSize?: number;
	onPageChange: (page: number) => void;
	onTakeChange?: (take: number) => void;
	pageSizeOptions?: number[];
};

function PagePagination({
	currentPage,
	totalItems,
	pageSize,
	onPageChange,
	onTakeChange,
	pageSizeOptions
}: PaginationT) {
	const handlePageChange = (page: number) => {
		onPageChange(page);
		window.scrollTo(0, 0);
	};

	const handleSizeChange = (size: number) => {
		onTakeChange && onTakeChange(size);
	};

	let location = useLocation();
	useEffect(() => {
		onPageChange(1);
	}, [location.pathname, totalItems]);

	const uniquePageSizeOptions = Array.from(new Set(pageSizeOptions));

	return (
		<div className="page-pagination">
			<Pagination
				itemRender={(page, type, originalElement) => {
					if (type === 'page') {
						return <a>{page}</a>;
					}
					return originalElement;
				}}
				hideOnSinglePage
				showSizeChanger={false}
				style={{alignSelf: 'flex-end'}}
				showTotal={total => (total ? t('totalRows', {total}) : '')}
				total={totalItems}
				current={currentPage}
				pageSize={pageSize}
				onChange={handlePageChange}
			/>
			<Select className="select" value={pageSize} onChange={handleSizeChange} style={{maxWidth: 170}}>
				{uniquePageSizeOptions.map(size => (
					<Option key={size} value={size}>
						{size} {t('pages.account.partner.buses.perpage')}
					</Option>
				))}
			</Select>
		</div>
	);
}

export default PagePagination;
