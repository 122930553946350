import API from '../api';
import axiosClient from '../utils/axiosClient';

const getauthOtp = async (data: any) => {
	const res = await axiosClient.post(API.AUTHOTP.authOtp, data);
	return res?.data;
};

const confirmEmailWithOtp = async (data: any) => {
	const res = await axiosClient.post(API.AUTHOTP.confirmEmailOtp, data);
	return res?.data;
};

const AUTHOTP = {
	getauthOtp,
	confirmEmailWithOtp
};

export default AUTHOTP;
